import axios from 'axios';
import { useI18n } from 'vue-i18n';

export class WagtailClient {
    constructor(apiBase, preview, token, content_type, locale, host) {
        this.preview = preview;
        this.token = token;
        this.content_type = content_type || 'application/json';

        let url = '';
        const additionalHeaders = {};
        if (apiBase) {
            url = apiBase;
        }
        url += '/v2/';
        const headers = {
            'Accept-Language': locale,
            ...additionalHeaders,
        };
        if (!process.client) {
            headers['Host'] = new URL(host).host;
        }
        this.client = axios.create({
            baseURL: url,
            maxRedirects: 0,
            headers,
        });
        this.client.interceptors.request.use((config) => {
            if (config.url.includes('preview')) {
                return config;
            }
            if (config.url[config.url.length - 1] !== '/') {
                config.url += '/';
            }
            return config;
        });
    }

    async getGenericPages() {
        const res = await this.client.get('pages/', {
            params: {
                type: 'cms.LumaBasePage',
                fields: 'url',
            },
        });

        // filter out pages that are not of type LumaBasePage
        const infos = res.data.items;
        res.data.items = infos.filter(function (item) {
            return item.meta.type === 'cms.LumaBasePage';
        });

        return res;
    }

    async getThankYouPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.ThankYouPage',
                fields: 'url',
            },
        });
    }

    async getTeamPages() {
        let res = await this.client.get('pages/', {
            params: {
                type: 'cms.TeamPage',
                fields: 'url',
            },
        });
        return res;
    }

    async getEmployeePages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.EmployeePage',
                fields: 'url',
            },
        });
    }

    async getEventCourseOverviewPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.EventCourseOverviewPage',
                fields: 'url',
            },
        });
    }

    async getCoursePages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.CoursePage',
                fields: 'url',
            },
        });
    }

    async getLocationOverviewPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.LocationOverviewPage',
                fields: 'url',
            },
        });
    }

    async getLocationPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.LocationPage',
                fields: 'url',
            },
        });
    }

    async getJobPostingOverviewPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.JobPostingOverviewPage',
                fields: 'url',
            },
        });
    }

    async getJobPostingPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.JobPostingPage',
                fields: 'url',
            },
        });
    }

    async getCataloguePages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.CataloguePage',
                fields: 'url',
            },
        });
    }

    async getCategoryPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.CategoryPage',
                fields: 'url',
            },
        });
    }

    async getProductPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.ProductPage',
                fields: 'url',
            },
        });
    }

    async getMagazinePages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.MagazinePage',
                fields: 'url',
            },
        });
    }

    async getMagazineCategoryPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.MagazineCategoryPage',
                fields: 'url',
            },
        });
    }

    async getMagazineContentPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.MagazineContentPage',
                fields: 'url',
            },
        });
    }

    async getMagazineTagPages() {
        return await this.client.get('pages/', {
            params: {
                type: 'cms.MagazineTagPage',
                fields: 'url',
            },
        });
    }

    async getPageByPath(path) {
        const slice = path.slice(3);
        const nuxtApp = useNuxtApp();
        if (slice === 'eview/' && typeof window !== 'undefined') {
            const params = window.location.search;
            const res = await this.client.get(`preview/${params}`, {});
            return res;
        }
        const { t } = useI18n();

        const result = await this.client
            .get(`pages/find/?html_path=${path}`, {})
            .catch((err) => {
                if (
                    err.response.status === 301 ||
                    err.response.status === 302
                ) {
                    // if the redirect goes to /de/api/v2/..., we need to return without navigating
                    // this should be fixed in the backend in the future.
                    if (
                        err.response.headers.location.startsWith(
                            '/de/api/v2',
                        ) ||
                        err.response.headers.location.startsWith('/fr/api/v2')
                    ) {
                        const language =
                            err.response.headers.location.split('/')[1];
                        throw createError({
                            statusCode: 404,
                            message: t('error.404.title'),
                            data: {
                                buttonText: t('error.404.back'),
                                title: t('error.404.pageTitle'),
                                language: language,
                            },
                        });
                    }
                    nuxtApp.runWithContext(() =>
                        navigateTo(err.response.headers.location, {
                            redirectCode: err.response.status,
                        }),
                    );
                    return;
                }
                throw createError({
                    statusCode: err.response.statusCode,
                    message: t('error.500.title'),
                    data: {
                        buttonText: t('error.404.back'),
                    },
                });
                return err;
            });
        return result;
    }

    async getPreviewPageByParams(params) {
        return await this.client.get(`preview/${params}`, {});
    }

    async getMenuItems() {
        return await this.client.get('menu/', {});
    }
}
