import { WagtailClient } from '../api/wagtail.js';

export function useWagtailClient(preview, token, content_type, locale) {
    const config = useRuntimeConfig();
    let url = config.public.apiBase;
    let host = config.public.apiBase;
    if (config.serverApiBase) {
        url = config.serverApiBase;
    }
    return new WagtailClient(url, preview, token, content_type, locale, host);
}
